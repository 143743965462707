import React from 'react';
import {
  Footer,
  IconCode,
  IconDesign,
  IconHomeUniform,
  Link,
  SvgIllustration,
} from 'docComponents';
import { Button, Environment, Icon } from 'hudl-uniform-ui-components';
import styles from './index.module.scss';
import { Layout } from 'docComponents';

export default class GlobalNav extends React.Component {
  componentDidMount() {
    const wrapper = document.querySelector('.illoWrapper');
    wrapper.classList.add('illoWrapper--loaded');
  }

  render() {
    return (
      <Layout>
        <Environment env="light">
          <div className={styles.mainContentWrapper}>
            <div className={'illoWrapper ' + styles.illustrationWrapper}>
              <Icon
                type="video"
                size="large"
                className={styles.iconAnimation1}
              />
              <Icon
                type="messages"
                size="large"
                className={styles.iconAnimation2}
              />
              <Icon
                type="search"
                size="large"
                className={styles.iconAnimation3}
              />
              <Icon
                type="playtools"
                size="large"
                className={styles.iconAnimation4}
              />
              <Icon
                type="edit"
                size="large"
                className={styles.iconAnimation5}
              />
              <Icon
                type="reports"
                size="large"
                className={styles.iconAnimation6}
              />
              <Icon
                type="exchanges"
                size="large"
                className={styles.iconAnimation7}
              />
              <Icon
                type="drawing"
                size="large"
                className={styles.iconAnimation8}
              />

              <SvgIllustration
                illustrationType="useravatar"
                className={styles.illoAnimation1}
              />
              <SvgIllustration
                illustrationType="environment"
                className={styles.illoAnimation2}
              />
              <SvgIllustration
                illustrationType="toast"
                className={styles.illoAnimation3}
              />
              <SvgIllustration
                illustrationType="selects"
                className={styles.illoAnimation4}
              />
              <SvgIllustration
                illustrationType="themes"
                className={styles.illoAnimation5}
              />
              <SvgIllustration
                illustrationType="forms"
                className={styles.illoAnimation6}
              />
              <SvgIllustration
                illustrationType="layering"
                className={styles.illoAnimation7}
              />

              <SvgIllustration
                shapeType="diamond"
                className={styles.shapeAnimation1}
              />
              <SvgIllustration
                shapeType="hexagon"
                className={styles.shapeAnimation2}
              />
              <SvgIllustration
                shapeType="rhombus"
                className={styles.shapeAnimation3}
              />
              <SvgIllustration
                shapeType="triangle"
                className={styles.shapeAnimation4}
              />
              <SvgIllustration
                shapeType="parallelogram"
                className={styles.shapeAnimation5}
              />
            </div>
            <div className={styles.layoutWrapper}>
              <div className={styles.heroWrapper}>
                <IconHomeUniform className={styles.uniformLogo} />
                <div className={styles.releaseVersion}>
                  <Link href="/release-notes" type="implied">
                    February 2020 Release
                  </Link>
                </div>
                <div className={styles.actionButtons}>
                  <Link href="/get-started/design" type="wrapper">
                    <Button
                      type="primary"
                      size="medium"
                      icon={
                        <IconDesign className={styles.actionButtonIcons} />
                      }>
                      Start Designing
                    </Button>
                  </Link>
                  <Link href="/get-started/code" type="wrapper">
                    <Button
                      type="primary"
                      size="medium"
                      icon={<IconCode className={styles.actionButtonIcons} />}>
                      Start Coding
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footerWrapper + ' mounted'}>
            <Footer />
          </div>
        </Environment>
      </Layout>
    );
  }
}
